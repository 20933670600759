<template lang="pug">
    div.main-container(:style="{backgroundSize:'cover', backgroundImage:'url(' + require('@/assets/img2/insigne-bg-stroke-grey.png') + ')'} ")
        div.overlay
        
        <!--  BEGIN CONTENT PART  -->
        div.main-content(id="content")
            div.layout-px-spacing
                div.page-header
                    div.page-title
                        h3 {{$t('teachgroupreport.reportgroup')}}
                    div
                       nav.breadcrumb-one(aria-label="breadcrumb")
                          ol.breadcrumb
                              li.breadcrumb-item
                                router-link(to="/teacher/teacherhome") {{$t('teachgroupreport.teadashboard')}}
                              li.breadcrumb-item.active(aria-current="page") {{$t('teachgroupreport.myreport')}}
                              
                div.row.mt-5.pb-5
                    div.col-md-11
                        div.card
                            div.card-header
                                div.row
                                    div.col-md-5
                                        h5 {{$t('teachgroupreport.choosegroup')}}
                                        select.selectpicker.col-6.mx-auto(@change="getData" v-model="idGroup")
                                            option(value="0") {{$t('teachgroupreport.choosegroup')}}    
                                            option(:value="group.idGroup" v-for="group in groupList") {{ group.sGroupName }}        
                                        button.btn.btn-sm.btn-success(style="margin:10px" type="button" v-if="idGroup != 0" @click="exportToExcel") {{$t('btns.btnexport')}}                
                                    div.col-md-5
                                        
                            div.card-body
                                div.table-responsive
                                    table.table.table-bordered.table-hover.table-striped.table-checkable.table-highlight-head.mb-4
                                        thead
                                            tr
                                                th {{$t('teachgroupreport.name')}}
                                                th {{$t('teachgroupreport.mail')}}
                                                th {{$t('teachgroupreport.code')}}
                                                th {{$t('teachgroupreport.examination')}}
                                                th {{$t('teachgroupreport.status')}}
                                                th {{$t('teachgroupreport.score')}}
                                                th {{$t('teachgroupreport.date')}}
                                                th {{$t('teachgroupreport.result')}}
                                                th {{$t('teachgroupreport.attempt')}}
                                        tbody
                                            tr(v-for="result in userResults")
                                                td {{ result.sName }}
                                                td {{ result.sEmail }}
                                                td {{ result.sAccessCode }}
                                                td {{ result.sExamName }}
                                                td {{ result.sExamStatus }}
                                                td {{ result.iScore }}
                                                td {{ result.dPresentationDate }}
                                                td {{ result.sResult }}
                                                td {{ result.iOportunities }}
</template>
<script>
/* eslint-disable */
export default {
    name: 'GroupReport',
    components: {
    },
    data() {
        return {
            idGroup: 0,
            userResults: [],
            groupList: []
        }
    },
    methods: {
        async getGroupsList() {
            await axios.get(apiURL + 'Group/getTeacherGroups?idTeacher=' + localStorage.CertIdP, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                this.groupList = response.data.groupsList
            } )
            .catch( (error)  => {
                console.log("error")
            } )
        },        
        async getData()
        {
            await axios.get(apiURL + 'Group/getGroupReport?idGroup=' + this.idGroup, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                this.userResults = response.data.userResults
            } )
            .catch( (error)  => {
                console.log(error)
            } )
        },
        async exportToExcel()
        {
            this.openLoading('Procesando ...');
            await axios.get(apiURL + 'Group/reportExportToExcel?idGroup=' + this.idGroup, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                Swal.close();
                if(response.data.sErrors.length > 0)
                {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: response.data.sErrors[0],
                        footer: ''
                    })
                    return
                }
                
                let a = document.createElement("a")
                a.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + response.data.base64CodesFile
                a.download = response.data.sFileName
                document.body.appendChild(a)
                a.click()
                a.remove()

                this.getData()
            } )
            .catch( (error) => {
                Swal.close();
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error,
                    footer: ''
                })
            } )
        }





    },
    mounted() {
        this.checkPermisions(this.$router.currentRoute.path)
        this.getGroupsList()
    }
}
</script>
